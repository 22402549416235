import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import { FAQJsonLd } from 'gatsby-plugin-next-seo'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql, StaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types"
import casinolistStyle from "../components/Casinolist.module.css"

class Viro extends React.Component {
  componentDidMount() {
    var links = document.links;
    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
        if (links[i].hostname != window.location.hostname) {
            links[i].target = '_blank';
            links[i].rel = 'noopener';
        }
    }
   
		var talletus = document.getElementsByClassName("talletus");
		var o;
		for (o = 0; o < talletus.length; o++) {
			var trustly = "<img src='/images/maksu/trustly.webp' width='75' height='50' title='Trustly' alt='trustly' class='lazyload'/>"
			var perinteiset = "<img src='/images/maksu/perinteiset.webp' width='75' height='50' title='Perinteiset maksutavat' alt='perinteiset' class='lazyload'/>"
			var brite = "<img src='/images/maksu/brite.webp' alt='brite' width='75' height='50' title='Brite' class='lazyload'/>"
			var zimpler = "<img src='/images/maksu/zimpler.webp' title='Zimpler' width='75' height='50' alt='zimpler' class='lazyload'/>"
			var str = talletus[o].innerHTML;

			var res = str.replace("Trustly", trustly).replace("Brite", brite).replace("Perinteiset", perinteiset).replace("Zimpler", zimpler);
			talletus[o].innerHTML = res;
		}

    var lisenssi = document.getElementsByClassName("lisenssi");
		var o;
		for (o = 0; o < lisenssi.length; o++) {
			var malta = "<img src='/images/lisenssi/mga.webp' width='75' height='75' title='Maltan lisenssi' alt='Maltan lisenssi' class='lazyload'/>"
			var viro = "<img src='/images/lisenssi/maksu-tolliamet.webp' width='75' height='75' title='Viron lisenssi' alt='Viron lisenssi' class='lazyload'/>"
      var curacao = "<img src='/images/lisenssi/curacao.webp' width='75' height='75' title='Curacaon lisenssi' alt='Curacaon lisenssi' class='lazyload'/>"
      var antillephone = "<img src='/images/lisenssi/antillephone.webp' width='75' height='75' title='Curacaon lisenssi / Antillephone' alt='Curacaon lisenssi / Antillephone' class='lazyload'/>"
      var str = lisenssi[o].innerHTML;

			var res = str.replace("Malta", malta).replace("Viro", viro).replace("Curacao", curacao).replace("Antillephone", antillephone);
			lisenssi[o].innerHTML = res;
		}

		
  }
  render() {
  return (
    <StaticQuery
      query = {graphql`
      query {
        contentfulMoneysivujenOsiot(slug: {eq: "viro"}) {
          slug
          sivu
          ylosanTeksti {
            json
          }
          kasinolista {
            kasinonNimi
            kasinonListanimi
            lause1Viro
            lause2Viro
            lisenssi
            rahansiirto
            logonTiedostonimi
            afflink
            kasinonListiedotViroSivu {
              json
              }
          }
          alaosanTeksti {
            json
          }
          alaosanTeksti2 {
            json
          }
        }
      }
      `}
      render={data => (
    <Layout>
      <GatsbySeo
      title='Viron uudet kasinot | Maksu- ja Tolliamet nettikasinot'
      description='Viro on noussut nopeassa ajassa Baltian uhkapelikeitaaksi. Maksu- ja Tolliametin myöntämä lisenssi on yksi luotetuimmista.'
   
   />
        <FAQJsonLd
            questions={[
              { question: 'Mikä viranomainen valvoo Virosta käsin toimivia kasinoita?', answer: 'Viron lisenssit myöntää ja niitä valvoo Maksu- ja Tolliamet, eli käytännössä Viron vero- ja tullihallinto.' },
              { question: 'Miten saan selville onko jollain tietyllä kasinolla Viron myöntämä lisenssi?', answer: 'Maksu- ja Tolliamet ylläpitää aina ajantasalla olevaa listaa myönnetyistä lisensseistä verkkosivuillaan. Voit tarkistaa lisenssit osoitteessa emta.ee ' },
            ]}
          />
        <Container>
        <div class="suodatin">
          <p><b>Suodata uudet nettikasinot -listaa:</b></p>
          <a class="nappi" href='/' target="_self">Uusi Kasino</a> <a class="nappi" href='/malta/' target="_self">Malta</a> <a class="nappi" href='/viro/' target="_self">Viro</a> <a class="nappi" href='/ilman-rekisteroitymista/' target="_self">Ilman rekisteröitymistä</a>
        <p><b>Selaat nyt:</b></p>
        <p><i>Viron uudet kasinot</i></p>
                    </div>
        <table>
          <tbody>
            {data.contentfulMoneysivujenOsiot.kasinolista.map(({logonTiedostonimi,kasinonListanimi,lause1Viro,lause2Viro,lisenssi,afflink,kasinonListiedotViroSivu,rahansiirto}) => (
            <tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={`/images/a/${logonTiedostonimi}`} alt={kasinonListanimi} title={kasinonListanimi} className="lazyload" width="120" height="50"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>&#10003; {kasinonListanimi}</h5>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<p>&#11088; {lause1Viro}</p>
                        <p>&#11088; {lause2Viro}</p>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={afflink} target="_blank">&#9889; Pelaa &#9889;</a>
                      <div className="talletus">{rahansiirto}</div>
										</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>

								<div className={casinolistStyle.casinoalaosa}>
                <div className="lisenssi">{lisenssi}</div>
                {documentToReactComponents(kasinonListiedotViroSivu.json)}
								</div>
							</td>
						</tr>
            ))}
          </tbody>
        </table>
        <div className="ylaosa">{documentToReactComponents(data.contentfulMoneysivujenOsiot.ylosanTeksti.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
        })}</div>
        <PageTitle>{data.contentfulMoneysivujenOsiot.sivu}</PageTitle>
        <div className="alaosa">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
      <div className="alaosa2">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti2.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
        <div class="suodatin3">
                <center><h2>FAQ - Kysyttyä</h2></center>
                <div>
                  <div><h5>&#128204; Mikä viranomainen valvoo Virosta käsin toimivia kasinoita? </h5></div>
                  <p>Viron lisenssit myöntää ja niitä valvoo Maksu- ja Tolliamet, eli käytännössä Viron vero- ja tullihallinto.</p>
                </div>
                <div>
                  <div><h5>&#128204; Miten saan selville onko jollain tietyllä kasinolla Viron myöntämä lisenssi? </h5></div>
                  <p>Maksu- ja Tolliamet ylläpitää aina ajantasalla olevaa listaa myönnetyistä lisensseistä verkkosivuillaan. Voit tarkistaa lisenssit osoitteessa emta.ee </p>
                </div>                            
              </div>
      
      </Container>
    </Layout>
    )}
    />
  )
  }
}

export default Viro

